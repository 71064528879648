import SOCBadge from '@/assets/footer/soc-2-badge.svg?react';
import CointrackerLogo from '@/assets/logo-white.svg?react';
import {
  Caption,
  Column,
  Grid,
  Link,
  Row,
  URLS,
  useGetAppsFlyerURLs,
  useHasMounted,
  useIsMobileWebview,
} from '@cointracker/ui';
import { Fragment } from 'react';
import {
  columnContent,
  columnTitle,
  darkModeFooter,
  disclaimer,
  disclaimerList,
  disclaimerListItem,
  footer,
  hiringContainer,
  lightModeFooter,
  navigationList,
  navigationListItem,
  navigationListLink,
  paragraph,
  roundLogo,
} from './PageBottomFooter.css';

import AppStorelogo from '@/assets/third-party/apple-app-store-white.svg?react';
import FacebookLogo from '@/assets/third-party/facebook.svg?react';
import GooglePlayLogo from '@/assets/third-party/google-play-white.svg?react';
import LinkedinLogo from '@/assets/third-party/linkedin.svg?react';
import RedditLogo from '@/assets/third-party/reddit.svg?react';
import TwitterLogo from '@/assets/third-party/twitter-x.svg?react';
import YoutubeLogo from '@/assets/third-party/youtube.svg?react';
import { useTheme } from '@/hooks/useTheme';
import classNames from 'classnames';

const handledCryptoCountries = ['Canada', 'Australia', 'United Kingdom'];

export function PageBottomFooter() {
  const hasMounted = useHasMounted();
  const currentDate = new Date();
  const { isDarkTheme } = useTheme();
  const user = {
    isAuthenticated: false,
    country: {
      name: 'United states',
    },
  };

  let countryName = '';
  if (user?.isAuthenticated) {
    countryName = user.country?.name;
  }

  const { iosUrl, androidUrl } = useGetAppsFlyerURLs();

  const isMobileWebview = useIsMobileWebview();

  if (isMobileWebview) {
    return null;
  }

  return (
    <footer
      className={classNames(
        footer,
        hasMounted && isDarkTheme ? darkModeFooter : lightModeFooter,
      )}
    >
      <Grid>
        <Row>
          <Column lg="3">
            <div className={columnContent}>
              <CointrackerLogo />
              <p className={paragraph}>
                Connect your cryptocurrency wallets and exchanges. Get your
                crypto and bitcoin taxes done in minutes.
              </p>
              <SOCBadge />
            </div>
          </Column>
          <Column offsetLg="1" offsetMd="1" lg="2" md="2" sm="6" xs="6">
            <div className={columnContent}>
              <h4 className={columnTitle}>Company</h4>
              <ul className={navigationList}>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    variant="greyStrong"
                    className={navigationListLink}
                    href={URLS.ABOUT}
                    target="_blank"
                  >
                    About
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    variant="greyStrong"
                    className={navigationListLink}
                    href={URLS.CAREERS}
                  >
                    Careers
                  </Link>
                  <div className={hiringContainer}>
                    <Caption size="small" variant="white">
                      HIRING
                    </Caption>
                  </div>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.OLD_ENTERPRISE_LANDING}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Enterprise
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.CPA_EXTERNAL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tax professionals
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={`/${URLS.TERMS}`}
                  >
                    Terms
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={`/${URLS.PRIVACY}`}
                  >
                    Privacy
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.DISCLAIMER}
                  >
                    Disclaimer
                  </Link>
                </li>
              </ul>
            </div>
          </Column>
          <Column lg="2" md="2" sm="6" xs="6">
            <div className={columnContent}>
              <h4 className={columnTitle}>Platform</h4>
              <ul className={navigationList}>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE}
                  >
                    Pricing
                  </Link>
                </li>
                <Fragment>
                  <li className={navigationListItem}>
                    <Link
                      as="a"
                      className={navigationListLink}
                      variant="greyStrong"
                      href={URLS.PRICES_PAGE}
                    >
                      Crypto prices
                    </Link>
                  </li>
                  <li className={navigationListItem}>
                    <Link
                      as="a"
                      className={navigationListLink}
                      variant="greyStrong"
                      href={URLS.INTEGRATIONS_PAGE}
                    >
                      Integrations
                    </Link>
                  </li>
                </Fragment>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={`/${URLS.SECURITY}`}
                  >
                    Security
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.STATUS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Status
                  </Link>
                </li>
              </ul>
            </div>
          </Column>
          <Column lg="2" md="2" sm="6" xs="6">
            <div className={columnContent}>
              <h4 className={columnTitle}>Resources</h4>
              <ul className={navigationList}>
                <li className={navigationListItem}>
                  {countryName === 'Canada' && (
                    <Link
                      as="a"
                      className={navigationListLink}
                      variant="greyStrong"
                      href={`${URLS.BLOG}/cryptocurrency-taxes-in-canada`}
                    >
                      Crypto tax guide
                    </Link>
                  )}
                  {countryName === 'Australia' && (
                    <Link
                      as="a"
                      className={navigationListLink}
                      variant="greyStrong"
                      href={`${URLS.BLOG}/australia-cryptocurrency-tax-guide`}
                    >
                      Crypto tax guide
                    </Link>
                  )}
                  {countryName === 'United Kingdom' && (
                    <Link
                      as="a"
                      className={navigationListLink}
                      variant="greyStrong"
                      href={`${URLS.BLOG}/cryptocurrency-taxation-in-the-united-kingdom`}
                    >
                      Crypto tax guide
                    </Link>
                  )}
                  {!handledCryptoCountries.includes(countryName) && (
                    <Link
                      as="a"
                      className={navigationListLink}
                      variant="greyStrong"
                      href={`${URLS.BLOG}/crypto-tax-guide`}
                    >
                      Crypto tax guide
                    </Link>
                  )}
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.BLOG}
                  >
                    Blog
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.SUPPORT}
                  >
                    Support
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.FEATURES}
                  >
                    Features
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.CALCULATOR}
                  >
                    Trade Calculator
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={URLS.PORTFOLIO_TRACKER}
                  >
                    Portfolio Tracker
                  </Link>
                </li>
              </ul>
            </div>
          </Column>
          <Column lg="2" md="2" sm="6" xs="6">
            <div className={columnContent}>
              <ul className={navigationList}>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={iosUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppStorelogo />
                  </Link>
                </li>
                <li className={navigationListItem}>
                  <Link
                    as="a"
                    className={navigationListLink}
                    variant="greyStrong"
                    href={androidUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GooglePlayLogo />
                  </Link>
                </li>
              </ul>
            </div>
          </Column>
        </Row>
        <Row alignItems="center" flexDirection="row-reverse">
          <Column lg="6" md="6" sm="12" xs="12">
            <ul className={disclaimerList}>
              <li className={disclaimerListItem}>
                <Link
                  as="a"
                  variant="greyStrong"
                  href="https://twitter.com/cointracker"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={roundLogo}>
                    <TwitterLogo />
                  </div>
                </Link>
              </li>
              <li className={disclaimerListItem}>
                <Link
                  as="a"
                  variant="greyStrong"
                  href="https://www.reddit.com/r/cointracker"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={roundLogo}>
                    <RedditLogo />
                  </div>
                </Link>
              </li>
              <li className={disclaimerListItem}>
                <Link
                  as="a"
                  variant="greyStrong"
                  href="https://www.linkedin.com/company/cointracker"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={roundLogo}>
                    <LinkedinLogo />
                  </div>
                </Link>
              </li>
              <li className={disclaimerListItem}>
                <Link
                  as="a"
                  variant="greyStrong"
                  href="https://www.facebook.com/cointracker"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={roundLogo}>
                    <FacebookLogo />
                  </div>
                </Link>
              </li>
              <li className={disclaimerListItem}>
                <Link
                  as="a"
                  variant="greyStrong"
                  href="https://www.youtube.com/channel/UCXaLV7_eI4VXPVrEAJpcs5g"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={roundLogo}>
                    <YoutubeLogo />
                  </div>
                </Link>
              </li>
            </ul>
          </Column>
          <Column lg="6" md="6" sm="12" xs="12">
            <p className={disclaimer}>
              &copy; CoinTracker {currentDate.getFullYear()}
            </p>
          </Column>
        </Row>
      </Grid>
    </footer>
  );
}
